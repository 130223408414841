

@font-face {
  font-family: Metropolis;
  font-weight: 100;
  src: url(~src/assets/fonts/WOFF/Metropolis-Thin.woff);
}

@font-face {
  font-family: Metropolis;
  font-weight: 200;
  src: url(~src/assets/fonts/WOFF/Metropolis-ExtraLight.woff);
}

@font-face {
  font-family: Metropolis;
  font-weight: 300;
  src: url(~src/assets/fonts/WOFF/Metropolis-Light.woff);
}

@font-face {
  font-family: Metropolis;
  font-weight: 400;
  src: url(~src/assets/fonts/WOFF/Metropolis-Regular.woff);
}

@font-face {
  font-family: Metropolis;
  font-weight: 500;
  src: url(~src/assets/fonts/WOFF/Metropolis-Medium.woff);
}

@font-face {
  font-family: Metropolis;
  font-weight: 600;
  src: url(~src/assets/fonts/WOFF/Metropolis-SemiBold.woff);
}

@font-face {
  font-family: Metropolis;
  font-weight: 700;
  src: url(~src/assets/fonts/WOFF/Metropolis-Bold.woff);
}

@font-face {
  font-family: Metropolis;
  font-weight: 800;
  src: url(~src/assets/fonts/WOFF/Metropolis-ExtraBold.woff);
}

@font-face {
  font-family: Metropolis;
  font-weight: 900;
  src: url(~src/assets/fonts/WOFF/Metropolis-Black.woff);
}

h2 {
  font-size: 25px;
  letter-spacing: 0.5px;
  color: $color-black;
  @apply font-semibold
}
h3 {
  letter-spacing: 0.36px;
  color: $color-black;
  font: normal normal bold 18px/22px Metropolis !important;
}
h4 {
  letter-spacing: 0.28px;
  color: $color-black;
  font: normal normal bold 15px/18px Metropolis !important;;
  @apply mb-4
}

p {
  letter-spacing: 0.28px;
  color: $color-black;
  font: normal normal normal 14px/22px Metropolis;
}

li {
  font-size: 12px;
  letter-spacing: 0.24px;
  line-height: 18px;
  color: $color-black;
}
