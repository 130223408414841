.ais-Pagination {
  @apply flex justify-center;
  .ais-Pagination-list {

    .ais-Pagination-item {
      &:hover {
        @apply text-black
      }

      &.ais-Pagination-item--selected, &:active {
        border-bottom: solid;
        .ais-Pagination-link {
          @apply shadow-none
        }
        @apply border-b-2 border-orange font-semibold
      }
      .ais-Pagination-link {
        background: transparent;
        height: auto;
        &:hover {
          background-image: none;
          border: none;
        }
        @apply text-sm p-0 border-none text-black m-0 shadow-none
      }
      @apply mx-2 cursor-pointer
    }

    @apply inline-block
  }
}


