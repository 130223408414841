@import "~src/theme/variables";
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
app-icon {
  display: inline-block;
}

app-navigation-bar {
  .mat-tab-link-container {
    mat-ink-bar {
      height: 4px !important;
    }
    .mat-tab-list {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.cdk-overlay-pane {
  z-index: 9999 !important;
}

::ng-deep .editor-container {
  height: 50vh !important;
  overflow-y: scroll !important;
}

::ng-deep .cdk-overlay-pane {
}

.general-text {
  font-size: 14px;
  line-height: 22px;
  color: #232634;
  font-weight: normal;
  margin: 0;
}

.tag-input {
  margin-top: 20px;
  border: 1px solid #dee3eb;
  border-radius: 4px;
  padding: 0 10px;
}

.text-grey {
  color: $color-button-blue;
}

.bg-grey {
  background-color: $color-gray-light;
  display: block;
}

.bg-milk {
  background-color: #fffbf8;
  display: block;
}

.page-fill {
  min-height: calc(100vh - 100px);
}

.text-box {
  max-width: 400px;
  align-self: center;
  justify-self: center;
}

.block-container {
  padding: 0;
  border-radius: 0.5rem;
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.example-fill-remaining-space {
  /* This fills the remaining space, by using flexbox.
       Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.text-black-50 {
  color: #7380a3 !important;
}
.mat-menu-panel {
  min-height: 0 !important;
}
.mat-menu-item {
  height: unset !important;
  line-height: 38px !important;
  &.block {
    :first-child {
      padding-top: 10px;
    }

    :last-child {
      padding-bottom: 10px;
    }

    line-height: 24px;
  }

  .text-small {
  }
}

.mat-menu-item:not(:last-child) {
  border-bottom: 1px solid $color-gray-light;
}

.mat-menu-content:not(:empty) {
  padding: 0 !important;
}

[readonly] {
  opacity: 0.5;
}

.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.btn-outline {
  color: white;
  border: 1px solid;
  border-color: white;
  text-transform: uppercase;
  background-color: $color-red-light;
}

.btn-light {
  color: $color-button-blue;
  border: 1px solid;
  border-color: $color-button-blue;
  text-transform: uppercase;
  background-color: $color-button-gray;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active,
.btn-outline.active {
  color: white;
  border-color: $color-red-light;
  background-color: $color-red-light;
}

.text-underline {
  text-decoration: underline;
}

a:not(.btn) {
  text-decoration: none;
}

:host {
}

label,
::placeholder {
  color: #7380a3;
}

form {
  font-size: 14px;
}

mat-form-field {
  font-size: 12px;
}

* {
  backface-visibility: hidden;
}

form .ql-toolbar.ql-snow {
  background-color: transparent;
  border-radius: 0;
  border: none;
}

form .ql-container.ql-snow {
  border-radius: 0.5rem;
  border: 1px solid $color-border-gray !important;
}

.list-item {
  position: relative;
}

.cdk-global-scrollblock {
  overflow: hidden;
}

/* Subnavigation */
.main-wrapper .mat-toolbar {
  background-color: transparent;
  border-radius: 0 !important;
  border-bottom: 3px solid $color-gray-light;
  /* EFF2F7 */
  min-height: unset;
  padding-bottom: 2px;
  color: $color-red-light;

  .mat-toolbar-row {
    height: unset;
    padding: 0;
  }
}

mwlResizable {
  box-sizing: border-box; // required for the enableGhostResize option to work
}

.cdk-drop-list:not(.table-container) {
  border-radius: 0.5rem;
  transition: background-color 0.2s ease-out, padding 0.2s ease-out;
  /*background-color: pink;*/
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #193b46;
    background: #232323;
    opacity: 0.75 !important;
  }
}

.dropdown-scroll-wrapper {
  max-height: 12rem;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
}

:focus {
  outline: none;
}

.cdk-drop-list-dragging:not(.table-container) {
  background: $blue !important;
  padding: 0.5rem;
  border-color: rgba(255, 255, 255, 0.2) !important;

  > * {
    border-color: rgba(255, 255, 255, 0.2) !important;
  }
}

.modal-dialog > .modal-content {
  background-color: transparent;
}

.form-control-text {
  border: none;
  display: inline-block;
  padding: 0;
  background: unset;
  outline: none;
  cursor: pointer;
  max-width: 100%;
  width: unset;
  text-align: right;
  color: $blue;
}

@media screen and (prefers-color-scheme: dark) {
  .form-control-text {
    color: $white;
  }
}

.form-control-text:focus {
  outline: none;
}

.datatable-body-cell-label {
  height: 100%;
}

.m-half {
  margin: 0.125rem !important;
}

.w-50px {
  width: 50px;
}

.w-75px {
  width: 75px;
}

.w-100px {
  width: 100px;
}

.w-150px {
  width: 150px;
}

.w-200px {
  width: 200px;
}

.z-index-2000,
.dropdown-menu {
  z-index: 2000 !important;
}

.popover {
  border-radius: 4.8px;
}

.cell-dark {
  background-color: darken($blue-secondary, 5%);
}

.cell-light {
  background-color: ($blue-secondary);
}

.content-wrapper {
  border-top: 1px solid $white;
}

.overlay-ignore-z {
  height: calc(100% - 4rem);
  width: calc(100% - 4rem);
  overflow-y: auto;
  border-radius: 0.25rem;
}

.pointer-events-none {
  pointer-events: none;
}

.btn-micro {
  transform: scale(0.75) translate(0px, -3px);
}

.modal {
  &.show .modal-dialog {
    transition: 0.25s all;
    opacity: 1;
    /*transform: translate(0, 5vh);*/
  }

  &.custom-show .modal-dialog {
    opacity: 1;
    /*transform: translate(0, 0);*/
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $white;
}

.table th {
  font-weight: normal;
}

.mat-drawer-container {
  background-color: transparent !important;
}

.sub-display {
  width: 100%;
}

.tooltip {
  pointer-events: none;
}

body > .tooltip {
  z-index: $zindex-editor + 100;
}

.h-1px {
  height: 1px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.placeholder-sm::placeholder {
  font-size: 75%;
  line-height: 1;
}

::placeholder {
  font-style: italic;
}

.text-italic {
  font-style: italic;
}

footer,
footer a {
  color: $color-black;
}

button:focus,
button {
  outline: none;
}

.btn.fa,
.btn.fal {
  font-weight: 300;
}

.nav-item {
  padding: 5px 0px 0px 5px;
}

.mat-icon.fal,
.mat-icon.fa {
  font-size: 20px;
}

$snackBarTextColor: white;
$snackBarBackgroundNormal: #66af85;
$snackBarActionColor: lightgray;

.alert-info,
.snackBarInfo {
  border-radius: 0.5rem !important;
  background-color: $snackBarBackgroundNormal;
  color: $snackBarTextColor;
  box-shadow: none !important;
}

.alert-error,
.error-snackbar {
  background-color: #fecc2f !important;
}

.mat-simple-snackbar > span {
  font-weight: bold;
}

.mat-simple-snackbar-action {
  .mat-button {
    .mat-button-wrapper {
      color: $snackBarActionColor;
    }
  }
}

.logo-icon {
  //background-image: url('~src/assets/logo-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
  display: block;
}

.debug,
pre {
  color: $white;
  padding: 1em;
  background-color: $gray-100;
  background-color: $color-red-light;
  border-radius: 8px;
  font-size: 11px;
}

pre {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}

.mat-button-wrapper app-icon {
  float: right;
}

.basket-icon {
  .mat-icon svg {
    max-width: 60%;
    max-height: 60%;
  }
}

hr {
  border-color: $color-icon;
}

::selection :not(input::selection) {
  background: transparent;
}

.scroll-y {
  overflow: hidden;
  overflow-y: auto;
  overflow-scrolling: touch;
}

.scroll-x {
  overflow: hidden;
  overflow-x: auto;
  overflow-scrolling: touch;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer::selection {
  background: transparent;
}

.cursor-default {
  cursor: default !important;
}

.cursor-drag {
  cursor: grab !important;
}

.cursor-drag:active {
  cursor: grabbing !important;
}

.upgrade-bar {
  width: 100%;
  height: 50px;
  background-color: #f7f8fb;
}

label {
  color: $blue;
  font-size: 80%;
  width: 100%;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  font-family: "Font Awesome 5 Pro" !important;
}

.dropdown-item,
a {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.word-wrap {
  word-wrap: break-word;
  word-break: break-word;
}

.circle,
.circle:empty {
  width: calc(1em - 1px);
  height: calc(1em - 1px);
  border-radius: 50%;
  display: inline-block;
  margin-top: 1px;
}

.text-small {
  font-size: 12px;
  margin: 0;
}

.text-12px {
  font-size: 12px;
}

.text-normal {
  font-size: 13px;
  font-weight: normal;
}

.text-large {
  font-size: 125%;
}

.text-12 {
  font-size: 12px;
  font-weight: normal;
}

.input-padding {
  padding: 0.375rem 0.75rem;
}

.light a.nav-item {
  color: $blue;
}

.dark a.nav-item {
  color: $white;
  opacity: 0.8;

  &:hover {
    color: $white;
    opacity: 1;
  }
}

@media screen and (prefers-color-scheme: dark) {
  .light a.nav-item {
    color: $white;
  }

  .dark a.nav-item {
    color: $white;
    opacity: 0.8;

    &:hover {
      color: $white;
      opacity: 1;
    }
  }
}

.comma-delimeter:not(:last-of-type)::after {
  content: ",";
  margin-right: 0.5rem;
}

ngb-typeahead-window {
  max-height: 250px;
  overflow-y: auto;
  overflow-scrolling: touch;
  z-index: $zindex-editor + 5;
}

ngb-highlight {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  /* word-break*/
  > li {
    list-style-position: inside;
    white-space: nowrap;
  }
}

.clamp {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 2em;
  line-height: 1em;
  max-height: 2em;
  overflow: hidden;
}

.corner-item {
  position: absolute !important;
  top: -5px;
  right: -5px;
  color: $white !important;
  background-color: $blue !important;
  /* background-color: $kb-dusty!important;*/
  border-radius: 0px 0px 0px 3px !important;
  width: 16px;
  height: 18px;
  font-size: 75%;
  z-index: 1;
  margin: 0 !important;
}

.corner-item.wide {
  width: auto;
}

.text-capitalize-firstletter:first-letter {
  text-transform: capitalize;
}

.text-light-blue {
  color: #7380a3;
}

.hover-show {
  cursor: default;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  > * {
    opacity: 0;
    transition: all 0.1s ease-in-out;
    transition-delay: 0.5s;
    transform: translateY(100%);
  }

  &:hover {
    opacity: 1;

    > * {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}

.ql-toolbar {
  border-radius: 4px 4px 0px 0px;
  background-color: $gray-200;
  background-color: $blue-secondary;
  border-color: rgba(0, 0, 0, 0.125) !important;
}

.ql-container {
  border-radius: 0px 0px 4px 4px;
  background-color: $white;
  border-color: rgba(0, 0, 0, 0.125) !important;
}

.ql-editor {
  font-family: $font, "Open Sans", -apple-system, system-ui, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-toolbar button.ql-active {
  color: #005f76 !important;
  stroke: #005f76 !important;
}

::ng-deep .mat-horizontal-stepper-header {
  border-radius: 1rem;
  height: 62px;
}

::ng-deep .mat-stepper-horizontal,
::ng-deep .mat-stepper-vertical {
  background-color: inherit !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: inherit !important;
}

.btn-splash {
  border-radius: 300px;
  text-transform: uppercase;
}

.btn.rounded {
  border-radius: 48px !important;
}

.btn-xl {
  font-size: 11px;
  padding: 15px 45px;
}

header.masthead .header-content {
  max-width: 500px;
  margin-bottom: 100px;
  text-align: center;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 200;
  color: $text-black;
}

h3 {
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 600;
}

h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $gray-900;
}

h1 {
  font-weight: 600;
  font-size: 38px;
  line-height: 48px;
  margin-bottom: 1em;
}

.bg-light-blue {
  background-color: $color-gray-light;
}

.mat-tab-label {
  opacity: 1 !important;
}

.strong,
.mat-tab-label {
  font-weight: 200;
  color: $gray-900;
}

.mat-tab-label-active {
  color: $color-red-light;
}

h2,
header.masthead h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 36px;
  color: $gray-900;
}

.dashboard header.masthead h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 0;
}

h2 {
}

header.masthead .device-container {
  max-width: 325px;
  margin-right: auto;
  margin-left: auto;
}

header.masthead .device-container .screen img {
  border-radius: 3px;
}

header.masthead {
  min-height: calc(100vh - 80px);
}

.dashboard header.masthead {
  min-height: unset;
}

.header-splash {
  background-color: #ffb788;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: -50px;
  background-attachment: local;
  height: 75px;

  app-user-profile-avatar {
    transform: translateY(100px);
  }
}

@media (min-width: 992px) {
  header.masthead {
    min-height: calc(100vh - 80px);
    padding-top: 0;
    padding-bottom: 0;
  }

  .dashboard header.masthead {
    min-height: unset;
  }

  header.masthead .header-content {
    margin-bottom: 0;
    text-align: left;
  }

  header.masthead .header-content h1 {
    font-size: 50px;
  }

  header.masthead .device-container {
    max-width: 325px;
  }
}

section.download {
  position: relative;
  padding: 150px 0;
}

section.download h2 {
  font-size: 50px;

  margin-top: 0;
}

section.download .badges .badge-link {
  display: block;
  margin-bottom: 25px;
}

section.download .badges .badge-link:last-child {
  margin-bottom: 0;
}

section.download .badges .badge-link img {
  height: 60px;
}

@media (min-width: 700px) {
  section.download .badges .badge-link {
    display: inline-block;
    margin-bottom: 0;
  }
}

@media (min-width: 700px) {
  section.download h2 {
    font-size: 70px;
  }
}

section.features .section-heading {
  margin-bottom: 100px;
}

section.features .section-heading h2 {
  margin-top: 0;
}

section.features .section-heading p {
  margin-bottom: 0;
}

section.features .device-container,
section.features .feature-item {
  max-width: 325px;
  margin: 0 auto;
}

section.features .device-container {
  margin-bottom: 100px;
}

@media (min-width: 992px) {
  section.features .device-container {
    margin-bottom: 0;
  }
}

section.features .feature-item {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

section.features .feature-item h3 {
  font-size: 30px;
}

section.features .feature-item i {
  font-size: 80px;

  display: block;
  margin-bottom: 15px;
  background: linear-gradient(to left, $color-orange-light, $color-red-light);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

section.cta {
  position: relative;
  padding: 250px 0;
  //background-image: url('~src/assets/bg-cta.jpg');
  background-position: center;
  background-size: cover;
}

section.cta .cta-content {
  position: relative;
  z-index: 1;
}

section.cta .cta-content h2 {
  font-size: 50px;

  max-width: 450px;
  margin-top: 0;
  margin-bottom: 25px;
}

.tab-header {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.01em;
  margin: 0;
}

.tab-header-active {
  color: #ff5050;
}

/* Browser */
@media (min-width: 700px) {
  section.cta .cta-content h2 {
    font-size: 80px;
  }
}

/* Mobil */
@media (max-width: 700px) {
  html {
    overflow: hidden;
  }

  .container.w-50 {
    width: 100% !important;
  }
}

.mat-list-item .mat-list-item-content {
  display: flex;
  justify-content: center !important;
}

.content-container {
  width: 100%;
  overflow: auto;
  height: 100vh;
  padding-top: 60px;
  transition: padding 0.5s ease-out;
  //overflow-x: hidden !important;
}

.page-container.headerHidden {
  .content-container {
    width: 100%;
  }
}

.navigation-fixed {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translate(0px, 0px);
  transition: 0.5s ease-out;
}

h1.navigation-company-name {
  margin-left: 8px;
}

.navigation-sidenav-container {
  flex: 1;
}

.navigation-is-mobile .navigation-sidenav-container {
  flex: 1 0 auto;
  justify-content: center;
}

.navigation-list {
  padding: 0;
  text-align: center;
  margin-left: 8px;
}

.navigation-spacer {
  flex: 1 1 auto;
}

.navigation-icon {
  padding: 0 22px;
}

.navigation-empty {
  height: 56px;
}

.logo {
  width: 164px;
  height: 80%;
  //background-image: url('~src/assets/logo.svg');
  background-repeat: no-repeat;
  background-position: left center;
  transition: all 0.2s ease-out;
  transform-origin: top left;
  max-height: 100%;
}

.logo-icon {
  width: 350px;
  height: 80%;
  //background-image: url('~src/assets/icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  transition: all 0.2s ease-out;
  transform-origin: top left;
  max-height: 100%;
}

.profile-icon {
  display: none;
}

.basket-icon {
  display: none;
  margin-left: 10px;
}

header.masthead:not(.dashboard header.masthead) {
  position: relative;
  width: 100%;
}

.nav-user-link {
  margin: 0;
  font-size: 15px;
  line-height: 15px;
}

.mat-tab-header {
  border: none !important;
}

.mat-tab-header:after {
  content: "";
  border-bottom: 3px solid $color-gray-light;
  width: 100%;
  transform: translateY(0px);
  position: absolute;
  bottom: 0;
}

.footer-container {
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

.course-card-list {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 63px !important;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
}

.container {
  padding-right: 20px;
  padding-left: 20px;
}
@media (min-width: 320px) {
    .container{
      max-width: 100% !important;
    }
}

.course-container {
  padding: 0;
}

.name-box {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.course-list-flex {
  display: flex;
  flex-flow: column wrap;
}

.text-group-members {
  color: #7380a3;

  font-size: 16px;
  margin-bottom: 15px;
  line-height: 22px;
}

.list-unstyled {
  margin-bottom: 0;
}

.card-wrap {
  cursor: pointer;
}

.mobile-update-profile-btn {
  width: 100%;
  text-transform: none;
  height: 50px;
  margin-top: 11px;
  margin-bottom: 30px;
}

.mobile-update-profile-btn:hover {
  background-color: $color-red-light;
  color: #fff;
  border-color: $color-red-light;
}

.desktop-update-profile-btn {
  display: none;
  text-transform: none;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.desktop-update-profile-btn:hover {
  background-color: $color-red-light;
  color: #fff;
  border-color: $color-red-light;
}

.contact-btn {
  width: 100%;
  margin-right: 20px;
  cursor: pointer;
  text-transform: none;
  background-color: $color-red-light;
  color: #fff;
  border-color: $color-red-light;
  height: 50px;
  border-radius: 4px;
}

.contact-btn:hover {
  background-color: #e54848;
  border-color: #e54848;
}

.profile-interest-box {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  padding-top: 20px;
  border-top: solid 1px #dee3eb;
  margin-top: 60px;
}

.profile-heading {
  color: #232531;
}

.profile-interest-list {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0;
}

.profile-about {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  padding-top: 20px;
  border-top: solid 1px #dee3eb;
  margin-top: 60px;
}

.profile-groups {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  padding-top: 20px;
  border-top: solid 1px #dee3eb;
  margin-top: 44px;
}

.readonly .mat-chip.mat-standard-chip {
  height: 40px;
  background-color: #eff2f7 !important;
  color: #232531 !important;
  border-radius: 60px;
  padding: 12px 22px;
}

.mat-chip.mat-standard-chip {
  height: 40px;
  background-color: #eff2f7 !important;
  color: #232531 !important;
  border-radius: 60px;
  padding: 12px 22px;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #7a8fdb !important;
  color: #ffffff !important;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  //background-color: #EFF2F7 !important;
  //color           : #232531 !important;
  background-color: #d2f4ea !important;
  color: #2e2e3a !important;
}

.mat-standard-chip.mat-chip-with-trailing-icon {
  padding: 12px 22px !important;
}

.mat-chip {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}

.mat-chip-list-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 !important;
}

.mat-standard-chip {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  border-radius: 60px;
  align-items: center;
  cursor: default;
}

.sidebar-container {
  width: 100%;
  z-index: 2;
}

.profile-menu {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  align-items: center;
}

.course-page-sub-heading {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
  color: #232634;
}

.group-page-heading {
  font-size: 40px;
  line-height: 50px;
  margin: 0;
  padding: o;
  font-weight: 600;
  margin-top: 5px;
}

.course-page-heading {
  font-size: 40px;
  line-height: 50px;
  margin: 0;
  padding: o;
  font-weight: 600;
  margin-top: 5px;
}

.group-page .mat-stroked-button {
  border: none;
  padding: 0;
}

.cog-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.group-page-heading-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.course-page-heading-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.cog-button {
  width: 180px;
  height: 40px;
  float: right;
  border-radius: 20px;
  display: flex;
  background-color: white;
  border: 1px solid #7380a3;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.cog-button:hover {
  background-color: #eff2f7;
  cursor: pointer;
}

.group-page-city {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #98a1b8;
  margin-top: 3px;
  margin-bottom: 0;
}

.course-page-city {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #98a1b8;
  margin-top: 3px;
  margin-bottom: 0;
}

.group-page-filter-section {
  margin-top: 20px;
}

.group-page-filter-heading {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #232634;
}

.course-page-date-range-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin: 0 auto;
}

.course-page-date-field {
  max-width: 340px;
  width: 100%;
  margin: 0 auto;
}

.course-page-filter-section {
  margin-top: 20px;
}

.course-page-filter-heading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #232634;
}

.filter-tag-description {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #232634;
}

.group-page .mat-chip-list-wrapper .mat-standard-chip {
  margin: 5px !important;
}

.course-page-container .mat-chip-list-wrapper .mat-standard-chip {
  margin: 5px !important;
}

.toolbar-title {
  margin: 0 4px 0 0 !important;
}

.course-card {
  padding: 0 !important;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.evnet-card-logo {
  width: 100px;
}

.filter-description {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: #232634;
  display: flex;
  margin: 0;
  padding: 0;
}

// modal-dialog .modal-dialog modal dialog
.backdrop-background {
  background-color: rgba(122, 143, 219, 0.9);
}

.profile-dialog-style {
  .mat-dialog-container {
    box-shadow: none !important;
    border-radius: 11px !important;
  }

  @media (max-width: 767px) {
    .mat-dialog-container {
      padding: 0 !important;
    }
  }
}

.subscription-dialog-style {
  .mat-dialog-container {
    box-shadow: none !important;
    border-radius: 11px !important;
  }

  .mat-dialog-container {
    padding: 0 !important;
  }
}

.chat-dialog-style {
  .mat-dialog-container {
    box-shadow: none !important;
    border-radius: 11px !important;
  }

  .mat-dialog-container {
    padding: 0 !important;
  }
}

// modal-dialog .modal-dialog modal dialog

.sidenav {
  display: none;
}

.sign-up-page {
  .header-splash {
  }

  .section-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;

    .sign-up-head {
      margin-bottom: 20px;

      .sign-up-flow {
        .loader {
          display: flex;
          width: 100vw;
          height: 100vh;
          justify-content: center;
          align-items: center;
        }

        .step-0 {
          width: 350px;
          margin-top: 50px;
          margin-bottom: 45px;

          .btn-outline {
            color: #ffffff !important;
          }

          .btn:hover {
            transform: scale(0.98);
            cursor: pointer;
          }
        }

        .step-1 {
        }

        .step-3 {
          width: 350px;
          margin-top: 50px;
          margin-bottom: 45px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-grow: 1;

          .info-text {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0;
            font-weight: normal;
            font-style: italic;
            color: #7380a3;
          }
        }

        .step-5 {
        }

        .sign-up-form {
          width: 350px;
          margin-top: 50px;
          //min-height: 660px;

          .btn-outline {
            color: #ffffff !important;
          }

          .btn:hover {
            transform: scale(0.98);
            cursor: pointer;
          }
        }

        .user-details-form {
        }
      }
    }
  }
}
