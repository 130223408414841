/* Importing Bootstrap SCSS file. */
@import "main";
@import "theme/material-override";
@import "theme/variables";
@import "theme/ngx-slide";
//@import "theme/bootstrap-import";
//@import "theme/bootstrap-override";
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@import "theme/button";
@import "theme/font";
@import "theme/algolia";
/* You can add global styles to this file, and also import other style files */

html {
  overflow: auto;
}
body {
  margin: 0;
  font-family: $font;
  letter-spacing: 0.28px;
  color: $color-black;
}

.shadow-border {
  box-shadow: 0 0 8px #00000025;;
}

.button {
  display: inline-block;
}

a:hover {
  color: $color-red-light;
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px !important;
  }
}

.leading-dots {
  line-height: 0.25;
}

.app-loading {
  .logo {
    width: 80px;
    height: 80px;

    // this way asset gets processed by webpack

    background: url(./assets/logo.svg) center center no-repeat;
    background-size: contain;

    mask-size: contain;
    animation: pulse 1.5s ease-in-out infinite;
    transform-origin: center;

    /*background-color: #1a3b46;*/
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    transform: scale(0.95);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.text-color-red-light {
  color: $color-red-light !important;
}

.text-color-blue-light {
  color: $color-blue-light !important;
}

.text-color-orange-light {
  color: $color-orange-light !important;
}

.ais-Hits-list {
  margin-top: 0;
  margin-bottom: 1em;
}
.ais-SearchBox {
  margin-bottom: 1em;
}
.ais-Pagination {
  margin-top: 1em;
}
.left-panel {
  float: left;
  width: 250px;
}
.right-panel {
  margin-left: 260px;
}
.ais-InstantSearch {
  max-width: 960px;
  overflow: hidden;
  margin: 0 auto;
}
.ais-Hits-item {
  margin-bottom: 1em;
  width: calc(50% - 1rem);
}

textarea {
  font-size: 0.875rem;
}

fill-green {
  fill: $color-secondary-green;
}

.text-break{
  word-break: break-word;
}

.pretty-scrollbar {
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: transparent;
    border: 1px solid $color-light-gray;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $color-blue-dark;
  }
  &-green{
    &::-webkit-scrollbar-thumb {
     background-color: $color-secondary-green !important;
    }
  }
}

.mat-typography {
  @media only screen and (max-width: 962px) {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  @media only screen and (max-width: 425px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}


app-algolia-tag-search {

  .ais-RefinementList-list {
    input {
      display: none;
    }
    .ais-RefinementList-count {
      display: none;
    }

    .ais-RefinementList-item {
      &.ais-RefinementList-item--selected {
        .ais-RefinementList-labelText {
          @apply bg-cyan border-cyan text-black
        }
      }
      .ais-RefinementList-labelText {
        span {
          font-weight: normal;
        }
        @apply m-1 border-1 border-solid border-white cursor-pointer justify-center px-3 py-1.5 rounded-full text-center text-white text-xs
      }
    }

    @apply flex flex-wrap
  }


  &.green {
    background-color: yellow;

    .ais-RefinementList-list {

      .ais-RefinementList-item {
        .ais-RefinementList-labelText {
          span {
            font-weight: normal;
          }
          @apply border-cyan text-black
        }
      }
    }
  }

}


app-product-card {
  &.focused {
    height: calc(100% + 50px);
    margin-top: -25px;
  }
  .product-description {
    ul {
      display: flex;
      flex-direction: column;
      margin: 4px 0;
      > li {
        margin: 3px 0;
        display: flex;
        align-items: center;
        &:before {
          content: url('/assets/icons/green-checkmark.svg');
          margin-right: 1rem;
          margin-bottom: -3px;
        }
      }
    }
  }
}

.ngx-slider .ngx-slider-bar-wrapper{
  width: 100% !important;
  left: 0 !important;;
}
