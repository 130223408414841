$color-red-light: #F4A266;
$color-red-dark: $color-red-light;
$color-blue-light: #7380a3;
$color-blue-dark: #7a8fdb;
$color-orange-light: #ffb788;
$color-orange-dark: #d4831d;
$color-gray-light: #eff2f7;
$color-gray-dark: #3e3d3a;
$color-black: #2e2e3a;
$color-green: #d2f4ea;
$color-button-blue: $color-blue-light;
$color-button-gray: $color-gray-light;
$color-icon: #dee3eb;
$color-border-gray: $color-gray-light;
$color-secondary-green: #339989;
$white: #fff;
$color-light-gray: #CED0D5;
$color-danger-dark: darkred;
$color-danger-light: red;


$font-weight-bold: 600;
$gray-100: #fffbf8;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: $color-blue-light;
$gray-800: #343a40;
$gray-900: #232634;
$black: $gray-900;

//
// Color system
//


$blue: $color-blue-light;
$blue-secondary: $color-blue-dark;
$blue-secondary-dark: #7280a3;
$darkblue: #27333c;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: $color-red-light;
$red: $color-red-light;
$orange: $color-orange-light;
$yellow: #fecc2f;
$green: #66af85;
$teal: #20c997;
$cyan: $color-blue-light;

/* Dark theme */
$dark-gray-800: #101416;
$dark-gray-500: #1d2931;
$dark-gray-200: #4c5a67;
$dark-gray-100: #d3d2d5;

$text-black: #313131;
$dark-primary-text: $text-black;
$light-primary-text: #919191;

$zindex-editor: 1120; /* Modal dialog is 1050 */


$font: Metropolis, Roboto, "Helvetica Neue", sans-serif
