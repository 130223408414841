ngx-slider.ngx-slider .ngx-slider-bar-wrapper {
  width: 191px;
}
ngx-slider.ngx-slider-span .ngx-slider-bubble .ngx-slider-limit .ngx-slider-ceil {
  visibility: hidden;
}
ngx-slider.ngx-slider-span .ngx-slider-bar-wrapper .ngx-slider-selection-bar {
  left: 20px;
  width: 191px;
}

ngx-slider.ngx-slider .ngx-slider-bar {
  background: #ffe4d1;
  height: 1px;
}
ngx-slider.ngx-slider .ngx-slider-bubble {
  color: #ffffff;
  font-size: 12px;
}
ngx-slider.ngx-slider .ngx-slider-pointer {
  width: 10px;
  height: 10px;
  top: auto; /* to remove the default positioning */
  bottom: -1px;
  background: #f4a266 0% 0% no-repeat padding-box;
}

ngx-slider.ngx-slider .ngx-slider-pointer:after {
  display: none;
}

ngx-slider.ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}

ngx-slider.ngx-slider .ngx-slider-limit {
  font-weight: bold;
  color: orange;
}

ngx-slider.ngx-slider .ngx-slider-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: #ffe4d1;
  top: -1px;
}

ngx-slider.ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: orange;
}
