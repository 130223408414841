@import "variables";

.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    border-top: 0.44375em solid transparent;
  }
  input {
    color: $text-black;
  }
}


mat-horizontal-stepper {
  font: $font;
  .mat-horizontal-stepper-header-container {
    justify-content: center;
    mat-step-header {
      padding: 10px !important;
      border-radius: 50%;
      &:after, &:before {
        content: '' !important;
        display: none !important;
      }

      .mat-step-icon {
        height: 32px;
        width: 32px;
        background-color: transparent;
        color: $color-light-gray;
        border: solid 1px $color-light-gray;
        font-size: 14px;
        &.mat-step-icon-selected, &.mat-step-icon-state-edit {
          background-color: $color-orange-light;
          color: white;
          border-color: $color-orange-light;
        }
      }
      .mat-step-label {
        display: none;
      }
    }
  }
  .mat-stepper-horizontal-line {
    display: none;
  }
}



mat-tab-group, .mat-tab-link-container {
  .mat-tab-header:after {
    display: none;
  }
  .mat-tab-labels, .mat-tab-links {
    justify-content: center;
    .mat-tab-label, .mat-tab-link {
      height: 1.875rem;
      padding: 0;
      margin: 0 24px;
      min-width: 0;
      color: $light-primary-text;
      font-size: 0.9375rem;
      &.mat-tab-label-active, &.mat-tab-label-active {
        font-weight: 600;
        color: $text-black;
      }
    }
  }

  mat-ink-bar {
    height: 3px !important;
  }
}
.mat-tab-header {
  margin-bottom: 0;
  &:after {
    display: none;
  }
}

body {

  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.9;
  }
  .cdk-overlay-dark-backdrop {
    background: $color-blue-dark;
  }
  .mat-dialog-container {
    position: relative;
    border-radius: 11px;
    box-shadow: 0px 0px 6px #00000015;

    .dialog-close-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      color: $light-primary-text;
      transform: scale(1.2);
    }
  }
  .cdk-overlay-container {
    .cdk-global-overlay-wrapper {
      .cdk-overlay-pane {
        max-width: 95vw !important;
        max-height: 95vh !important;
      }
    }
  }
}

.mat-tab-list .mat-tab-links .mat-tab-link {
  opacity: 1;
  color: $color-black;
}

.mat-checkbox-multiline {
  .mat-checkbox-label {
    font-size: 16px;
    white-space: normal !important;
  }
}
