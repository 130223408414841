@import 'variables';


@mixin btnVariant($name, $primaryColor, $hoverColor: 0) {

  .btn-#{$name} {

    white-space: nowrap;
    background-color: $primaryColor;
    font: normal normal 15px/18px Metropolis;
    font-weight: 600;
    border-radius: 4px;
    color: #fff;
    letter-spacing: 1px;
    border: solid 1px $primaryColor;
    padding: 12px 30px;
    transition: .2s;
    outline-style: none;
    text-align: center;

    &.outline {
      background-color: transparent;
      color: $primaryColor;
    }

    @if ($hoverColor != 0) {
      &:hover:not(.outline) {
        background-color: $hoverColor;
        border-color: $hoverColor;
        color: #fff;
      }

      &.outline:hover {
        color: #fff;
        background-color: $primaryColor;
        border-color: $primaryColor;
      }
    }
  }

}


@include btnVariant('orange', $color-red-light, $color-orange-dark);
@include btnVariant('danger', $color-danger-light, $color-danger-dark);
@include btnVariant('gray', $color-light-gray);
@include btnVariant('white', #fff);
@include btnVariant('green', $color-secondary-green, #2f746a);
@include btnVariant('black', $color-black, #18181a);

button:not([class*="btn-"]):not(.transparent):not([disabled]),.button {
  @extend .btn-orange;
}
button[disabled] {
  @extend .btn-gray;
  cursor: inherit;
}

